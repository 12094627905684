import { Auth0Provider } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { UserUpdate } from '@/dash/components'
import { ApiProvider, I18nProvider, TenancyProvider } from '@/dash/contexts'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://d472c0903ad347b08e9e1c4e2f7f538b@o363217.ingest.sentry.io/6353473',
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

const root = createRoot(document.getElementById('root'))
const queryClient = new QueryClient()

root.render(
  /*
   Temporarily disabled StrictMode becuase it breaks @headlessui/react
   Transition components.  Can Re-enable once support has been added for
   React 18+ and @headlessui/react:

   https://github.com/tailwindlabs/headlessui/issues/681
  */
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <I18nProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <Router>
            <Auth0Provider
              domain={process.env.REACT_APP_AUTH0_DOMAIN}
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
              audience={process.env.REACT_APP_AUTH0_AUDIENCE}
              redirectUri={window.location.origin}
            >
              <ApiProvider>
                <UserUpdate />
                <TenancyProvider>
                  <App />
                </TenancyProvider>
              </ApiProvider>
            </Auth0Provider>
          </Router>
        </QueryClientProvider>
      </I18nProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
