import React from 'react'
import { FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import Form from './Base'

const schema = Yup.object().shape({})

export default function AddApiKey({ ...formProps }) {
  return (
    <Form
      initialValues={{
        confirm: '',
      }}
      validationSchema={schema}
      {...formProps}
    >
      {({ values }) => (
        <div className="space-y-6">
          <Form.InputGroup
            id="confirm"
            name="confirm"
            hint={
              <FormattedMessage
                description="confirm delete form confirm hint label"
                defaultMessage='Please type "delete" above to confirm'
              />
            }
            label={
              <FormattedMessage
                description="confirm delete form confirm input label"
                defaultMessage="Confirm"
              />
            }
          />
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Form.SubmitButton disabled={values.confirm !== 'delete'}>
              <FormattedMessage
                description="confirm delete form submit button label"
                defaultMessage="Delete"
              />
            </Form.SubmitButton>
          </div>
        </div>
      )}
    </Form>
  )
}
