import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function Loading() {
  return (
    <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="flex flex-col">
          <FontAwesomeIcon
            icon={faSpinnerThird}
            className="fa-2xl fa-spin fa-spin-faster"
          />
        </main>
      </div>
    </div>
  )
}
