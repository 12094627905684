import React from 'react'
import { FormattedMessage } from 'react-intl'

export default function Team({
  invites,
  users,
  onRemoveInvite,
  onChangeRole,
  onRemoveUser,
}) {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <FormattedMessage
              description="team table email header"
              defaultMessage="Email"
            />
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <FormattedMessage
              description="team table role header"
              defaultMessage="Role"
            />
          </th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
            <span className="sr-only">
              <FormattedMessage
                description="team table actions header"
                defaultMessage="Actions"
              />
            </span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {invites.map(i => (
          <tr key={i.email} className="group hover:bg-gray-50">
            <td className="whitespace-nowrap px-3 py-4 text-sm italic text-gray-500">
              {i.email}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {new Date(`${i.expiration}Z`) < new Date() ? (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                  <FormattedMessage
                    description="team table invite expired label"
                    defaultMessage="expired"
                  />
                </span>
              ) : (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                  <FormattedMessage
                    description="team table invite pending label"
                    defaultMessage="pending"
                  />
                </span>
              )}
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
              <div className="invisible group-hover:visible">
                <button
                  onClick={() => onRemoveInvite(i)}
                  className="font-medium text-red-600 hover:text-red-800 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  <FormattedMessage
                    description="team table remove invite button label"
                    defaultMessage="Remove"
                  />
                </button>
              </div>
            </td>
          </tr>
        ))}
        {users.map(u => (
          <tr key={u.email} className="group hover:bg-gray-50">
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
              {u.email}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800 capitalize">
              {u.role}
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
              <div className="invisible group-hover:visible">
                {u.role !== 'owner' && (
                  <>
                    <button
                      onClick={() =>
                        onChangeRole({
                          ...u,
                          role: u.role === 'member' ? 'admin' : 'member',
                        })
                      }
                      className="font-medium text-indigo-600 hover:text-indigo-900 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-3"
                    >
                      {u.role === 'admin' ? (
                        <FormattedMessage
                          description="team table invite button label"
                          defaultMessage="Change to Member"
                        />
                      ) : (
                        <FormattedMessage
                          description="team table invite button label"
                          defaultMessage="Change to Admin"
                        />
                      )}
                    </button>
                    <button
                      onClick={() => onRemoveUser(u)}
                      className="font-medium text-red-600 hover:text-red-800 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                      <FormattedMessage
                        description="team table remove invite button label"
                        defaultMessage="Remove"
                      />
                    </button>
                  </>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
