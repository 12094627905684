import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { compile } from 'path-to-regexp'
import React from 'react'

export const API_URL = process.env.REACT_APP_API_URL

export const ApiContext = React.createContext({})

/**
 * Build a url path with params
 *
 * Example:
 *    urls.organization({id: '1'}) -> "/v1/organization/1/"
 *
 * @param {string} path - the path template
 * @returns {function} a function to feed url params to and compile a URL
 */
const url = path => params => compile(path)(params)

const urls = {
  currentUser: url('/v1/users/current'),
  organizations: url('/v1/organizations'),
  organization: url('/v1/organizations/:id/'),
  join: url('/v1/organizations/:id/join/'),
  organizationUsers: url('/v1/organizations/:organizationId/users/'),
  organizationUser: url('/v1/organizations/:organizationId/users/:id/'),
  invites: url('/v1/organizations/:organizationId/invites/'),
  invite: url('/v1/organizations/:organizationId/invites/:id/'),
  apiKeys: url('/v1/organizations/:organizationId/keys/'),
  apiKey: url('/v1/organizations/:organizationId/keys/:id/'),
}

/**
 * Map all apis to the specified client
 *
 * @param {*} client - the axios client object to use
 * @returns {Object} apis
 */
const getApis = client => ({
  /**
   * Retrieve the current user
   *
   * IMPORTANT: we just try to keep this user object in sync with the
   * Auth0 user object, but we don't need to do much else with it, so
   * try to stick with using `useAuth().user` for user details.
   *
   * @returns {Object} user - the current user from our API
   */
  fetchCurrentUser: async () => {
    const resp = await client.get(urls.currentUser())
    return resp.data
  },

  /**
   * Update the current user
   *
   * @returns {Object} user - the current user from our API
   */
  updateCurrentUser: async data => {
    const resp = await client.patch(urls.currentUser(), { ...data })
    return resp.data
  },

  /**
   * Retrieve organizations
   *
   * @returns {Array} organizations - list of the current user's organizations
   */
  fetchOrganizations: async () => {
    const resp = await client.get(urls.organizations())
    return resp.data
  },

  /**
   * Create an organization
   *
   * @returns {Object} organization - the newly created organization
   */
  createOrganization: async organization => {
    const resp = await client.post(urls.organizations(), {
      ...organization,
    })
    return resp.data
  },

  destroyOrganization: async organizationId => {
    await client.delete(urls.organization({ id: organizationId }))
  },

  /**
   * Retrieve organization users
   *
   * @returns {Array} users - list of the organization's users and roles
   */
  fetchOrganizationUsers: async organizationId => {
    const resp = await client.get(urls.organizationUsers({ organizationId }))
    return resp.data
  },

  /**
   * Update an organization user
   *
   * @returns {Object} user - the organization user
   */
  updateOrganizationUser: async (organizationId, id, user) => {
    const resp = await client.put(
      urls.organizationUser({ organizationId, id }),
      {
        ...user,
      },
    )
    return resp.data
  },

  /**
   * Destroy an organization user
   * @returns {null}
   */
  destroyOrganizationUser: async (organizationId, id) => {
    await client.delete(urls.organizationUser({ organizationId, id }))
  },

  /**
   * Retrieve invites
   *
   * @returns {Array} invites - list of the organization's invites
   */
  fetchInvites: async organizationId => {
    const resp = await client.get(urls.invites({ organizationId }))
    return resp.data
  },

  /**
   * Create an invite
   *
   * @returns {Object} invite - the newly created invite
   */
  createInvite: async (organizationId, invite) => {
    const resp = await client.post(urls.invites({ organizationId }), {
      ...invite,
    })
    return resp.data
  },

  /**
   * Destroy an invite
   *
   * @returns {null}
   */
  destroyInvite: async (organizationId, id) => {
    await client.delete(urls.invite({ organizationId, id }))
  },

  /**
   * Check invite code
   */
  checkInvite: async (id, code) => {
    const resp = await client.get(urls.join({ id }), {
      params: {
        code,
      },
    })
    return resp.data
  },

  /**
   * Accept invite
   */
  acceptInvite: async (id, code) => {
    await client.post(urls.join({ id }), {
      code,
    })
  },

  /**
   * Retrieve api keys
   *
   * @returns {Array} apiKeys - list of the organization's api keys
   */
  fetchApiKeys: async organizationId => {
    const resp = await client.get(urls.apiKeys({ organizationId }))
    return resp.data
  },

  /**
   * Create an api key
   *
   * @returns {Object} apiKey - the newly created api key
   */
  createApiKey: async (organizationId, apiKey) => {
    const resp = await client.post(urls.apiKeys({ organizationId }), {
      ...apiKey,
    })
    return resp.data
  },

  /**
   * Destroy an api key
   *
   * @returns {null}
   */
  destroyApiKey: async (organizationId, id) => {
    await client.delete(urls.apiKey({ organizationId, id }))
  },
})

/**
 * ApiProvider gives child components access to a single client bound to each of
 * our API endpoints and automagically manages credentials for authenticating
 * requests.
 */
export default function ApiProvider({ children }) {
  const { getAccessTokenSilently } = useAuth0()

  const client = axios.create({
    baseURL: API_URL,
  })

  client.interceptors.request.use(
    async config => {
      // Automatically include the auth token when communicating with the
      // API.
      if (config.baseURL === API_URL && !config.headers.Authorization) {
        // set auth header with token
        const token = await getAccessTokenSilently()
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }
      return config
    },
    error => Promise.reject(error),
  )

  client.interceptors.response.use(
    response => response,
    error => {
      // a 401 indicates the user is not authorized (the token did not
      // validate), so we should re-authenticate the user.
      if (error.response && error.response.status === 401) {
        // TODO: re-enable once everything is working
        // logout()
      }
      return Promise.reject(error)
    },
  )

  return (
    <ApiContext.Provider value={getApis(client)}>
      {children}
    </ApiContext.Provider>
  )
}
