/**
 * Sleep for specified time
 *
 * @param {int} ms - milliseconds to sleep
 */
export function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

/**
 * Make string title case
 *
 * @param {string} str
 */
export function toTitleCase(str) {
  return str
    ?.split(/[-_ ]/)
    ?.map(s => s[0].toUpperCase() + s.slice(1))
    ?.join(' ')
}
