import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { toTitleCase } from '@/dash/utils'
import { LS_AUTH_RETURN_LOCATION } from '@/dash/constants'
import { useTenancy } from '@/dash/hooks'
import { AppLayout } from '@/dash/layouts'
import * as pages from '@/dash/pages'

export default function App() {
  const auth = useAuth0()
  const tenancy = useTenancy()
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    // Redirect to login if the user isn't authenticated, but track the
    // url so we can return to it once we get back.
    if (!auth.isLoading && !auth.isAuthenticated) {
      const returnLoc = JSON.stringify(location)
      localStorage.setItem(LS_AUTH_RETURN_LOCATION, returnLoc)
      auth.loginWithRedirect()
    }

    // Handle redirects after returning from authentication (once Auth0
    // is finished authenticating)
    if (!auth.isLoading && auth.isAuthenticated) {
      try {
        const returnLoc = JSON.parse(
          localStorage.getItem(LS_AUTH_RETURN_LOCATION),
        )
        localStorage.removeItem(LS_AUTH_RETURN_LOCATION)
        returnLoc && navigate(returnLoc, { replace: true })
      } catch (err) {
        // invalid location JSON, no-op
      }
    }
  })

  // Handle authentication errors
  if (auth.error) {
    return (
      <pages.Error
        title={toTitleCase(auth.error.error)}
        msg={auth.error.message}
        actionPrimary={
          <button
            onClick={auth.loginWithRedirect}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FormattedMessage
              description="error page login button label"
              defaultMessage="Login"
            />
          </button>
        }
      />
    )
  }
  // Show loading page if we're waiting for initial startup
  if (
    auth.isLoading ||
    !auth.isAuthenticated ||
    tenancy.isLoading ||
    tenancy.isSwitching
  ) {
    return <pages.Loading />
  }
  // Require users to have at least one organization to get started. If
  // they are trying to join an organization, let them go there instead.

  if (
    !location.pathname.startsWith('/join/') &&
    !location.pathname.startsWith('/organizations/new') &&
    !tenancy.organization
  ) {
    navigate({
      pathname: '/organizations/new',
      search: '?initial=1',
    })
  }

  // We've made it through our prechecks, start routing.
  return (
    <Routes>
      {/* App routes */}
      <Route path="/organizations/new" element={<pages.NewOrganization />} />
      <Route
        path="/organizations/switch"
        element={<pages.SwitchOrganization />}
      />
      <Route path="/join/:id" element={<pages.Join />} />
      <Route path="/" element={<AppLayout />}>
        <Route index element={<pages.Dashboard />} />
        <Route path="settings/*" element={<pages.Settings />} />
        <Route path="logout" element={<pages.Logout />} />
      </Route>

      {/* Unhandled routes (404) */}
      <Route
        path="*"
        element={
          <pages.Error
            prefix={
              <FormattedMessage
                description="not found page title prefix"
                defaultMessage="404"
              />
            }
            title={
              <FormattedMessage
                description="not found page title"
                defaultMessage="Page not found"
              />
            }
            msg={
              <FormattedMessage
                description="not found page message"
                defaultMessage="Please check the URL in the address bar and try again."
              />
            }
          />
        }
      />
    </Routes>
  )
}
