import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { Dialog } from '@headlessui/react'
import Example from '@/dash/components/Modal'
import { useState } from 'react'
import { useTenancy } from '@/dash/hooks'
import { useNavigate } from 'react-router-dom'

export default function General() {
  const { removeOrganization } = useTenancy()
  const [modalVisible, setModalVisible] = useState(false)
  const navigate = useNavigate()

  const hideModal = () => {
    setModalVisible(false)
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const deleteOrganization = async () => {
    await removeOrganization()
    hideModal()

    navigate({
      pathname: '/organizations/switch',
    })
  }

  const deleteMessage = (
    <FormattedMessage
      description="general settings delete organization label"
      defaultMessage="Delete this organization"
    />
  )

  const deleteOrganizationModal = (
    <>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <FontAwesomeIcon
            icon={faExclamation}
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Delete Organization
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to delete this organization? This will
              delete the organization and all associated data permanently. This
              action cannot be undone.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => deleteOrganization()}
        >
          Delete Organization
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={() => hideModal()}
        >
          Cancel
        </button>
      </div>
    </>
  )

  return (
    <>
      <div className="mt-3">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="mt-2 text-sm text-gray-700">
              <FormattedMessage
                description="general settings page description"
                defaultMessage="Manage your organization and general settings"
              />
            </p>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2 className="text-lg leading-6 font-medium text-red-500">
                    <FormattedMessage
                      description="general settings danger zone title"
                      defaultMessage="Danger Zone"
                    />
                  </h2>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <div className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 grid grid-cols-2 sm:gap-4 sm:px-6">
                      <div>
                        <div className="text-sm font-medium text-gray-500">
                          {deleteMessage}
                        </div>
                        <p className="mt-2 text-sm text-gray-700">
                          <FormattedMessage
                            description="general settings delete organization message"
                            defaultMessage="Immediately delete this organization and all associated data. This action cannot be undone."
                          />
                        </p>
                      </div>
                      <div className="flex justify-end items-center ">
                        <button
                          onClick={() => showModal()}
                          className="bg-red-600 hover:bg-red-700 inline-flex items-center justify-center py-2 px-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          {deleteMessage}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Example show={modalVisible}>{deleteOrganizationModal}</Example>
      </div>
    </>
  )
}
