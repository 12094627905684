import React from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

export default function ApiKeys({ apiKeys, onRemoveKey }) {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <FormattedMessage
              description="api keys table name header"
              defaultMessage="Name"
            />
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <FormattedMessage
              description="api keys table last used header"
              defaultMessage="Last used"
            />
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <FormattedMessage
              description="api keys table created header"
              defaultMessage="Created"
            />
          </th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
            <span className="sr-only">
              <FormattedMessage
                description="api keys table actions header"
                defaultMessage="Actions"
              />
            </span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {apiKeys.length > 0 ? (
          apiKeys.map(k => (
            <tr key={k.id} className="group hover:bg-gray-50">
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
                {k.label}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
                {k.last_used ? (
                  <FormattedDate
                    value={`${new Date(k.last_used)}`}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  />
                ) : (
                  '–'
                )}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
                <FormattedDate
                  value={`${new Date(k.created)}`}
                  year="numeric"
                  month="short"
                  day="2-digit"
                />
              </td>
              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <div className="invisible group-hover:visible">
                  <button
                    onClick={() => onRemoveKey(k)}
                    className="font-medium text-red-600 hover:text-red-800 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    <FormattedMessage
                      description="api key table remove key button label"
                      defaultMessage="Remove"
                    />
                  </button>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td
              colSpan={4}
              className="whitespace-nowrap px-3 py-4 text-sm italic text-gray-500"
            >
              <FormattedMessage
                description="api key table no keys message"
                defaultMessage="No API keys"
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
