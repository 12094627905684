import { useAuth0 } from '@auth0/auth0-react'
import { Q_CURRENT_USER } from '@/dash/constants'
import { useApi } from '@/dash/hooks'
import React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

/**
 * This component just sits below the Auth0Provider and the ApiProvider
 * and watches for changes to the Auth0 user object on the initial page
 * load. If the user data has changed, we update the Django user object
 * on our end.
 */
export default function UserUpdate() {
  const [initial, setInitial] = React.useState(true)
  const { user, isAuthenticated } = useAuth0()
  const { fetchCurrentUser, updateCurrentUser } = useApi()
  const queryClient = useQueryClient()

  const needsUpdate = data =>
    data.email !== user.email ||
    data.first_name !== user.given_name ||
    data.last_name !== user.family_name

  useQuery([Q_CURRENT_USER], fetchCurrentUser, {
    enabled: initial && isAuthenticated,
    onSuccess: data => {
      // only run once
      setInitial(false)
      // check if we need to update
      if (needsUpdate(data)) {
        updateCurrentUser({
          email: user.email,
          first_name: user.given_name,
          last_name: user.family_name,
        }).then(data => {
          queryClient.setQueryData([Q_CURRENT_USER], data)
        })
      }
    },
  })

  return null
}
