import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleInfo,
  faCircleCheck,
  faCircleXmark,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import classNames from 'classnames'
import React from 'react'

const variants = {
  info: {
    icon: faCircleInfo,
    text: 'text-blue-400',
    header: 'text-blue-800',
    body: 'text-blue-700',
    background: 'bg-blue-50',
  },
  success: {
    icon: faCircleCheck,
    text: 'text-green-400',
    header: 'text-green-800',
    body: 'text-green-700',
    background: 'bg-green-50',
  },
  warning: {
    icon: faTriangleExclamation,
    text: 'text-yellow-400',
    header: 'text-yellow-800',
    body: 'text-yellow-700',
    background: 'bg-yellow-50',
  },
  danger: {
    icon: faCircleXmark,
    text: 'text-red-400',
    header: 'text-red-800',
    body: 'text-red-700',
    background: 'bg-red-50',
  },
}

export default function Alert({
  title,
  children,
  className,
  variant = 'info',
}) {
  const { icon, text, header, body, background } = variants[variant]

  return (
    <div className={classNames(className, 'rounded-md p-4', background)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <FontAwesomeIcon
            icon={icon}
            className={classNames('h-5 w-5', text)}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          {title && (
            <h3 className={classNames('text-sm font-medium', header)}>
              Attention needed
            </h3>
          )}
          <div className={classNames({ 'mt-2': title }, 'text-sm', body)}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
