import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import React from 'react'

/**
 *  This is the outer shell from the modal examples on tailwindui.com.
 *  You can use it do display whatever content you want. Here's an
 *  an example of what `children` might look like:
 *
 *    <div className="sm:flex sm:items-start">
 *      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
 *        <ExclamationIcon
 *          className="h-6 w-6 text-red-600"
 *          aria-hidden="true"
 *        />
 *      </div>
 *      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
 *        <Dialog.Title
 *          as="h3"
 *          className="text-lg leading-6 font-medium text-gray-900"
 *        >
 *          Deactivate account
 *        </Dialog.Title>
 *        <div className="mt-2">
 *          <p className="text-sm text-gray-500">
 *            Are you sure you want to deactivate your account? All of your
 *            data will be permanently removed from our servers forever. This
 *            action cannot be undone.
 *          </p>
 *        </div>
 *      </div>
 *    </div>
 *    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
 *      <button
 *        type="button"
 *        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
 *        onClick={() => setShow(false)}
 *      >
 *        Deactivate
 *      </button>
 *      <button
 *        type="button"
 *        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
 *        onClick={() => setShow(false)}
 *      >
 *        Cancel
 *      </button>
 *    </div>
 */

export default function Example({ show, dismiss, children }) {
  return (
    <Transition.Root show={show} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={dismiss || (() => {})}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  {dismiss && (
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={dismiss}
                    >
                      <span className="sr-only">Close</span>
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="w-6"
                        aria-hidden="true"
                      />
                    </button>
                  )}
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
