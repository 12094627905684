import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTenancy } from '@/dash/hooks'
import { CenteredLayout } from '@/dash/layouts'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function SwitchOrganization() {
  const { organizations, selectOrganization } = useTenancy()
  const navigate = useNavigate()
  const location = useLocation()

  // Redirect to the page set in state or dashboard by default
  const from = location.state?.from?.pathname || '/'

  const onSelect = organization => {
    selectOrganization(organization)
    navigate(from, { replace: true })
  }

  return (
    <CenteredLayout>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            <FormattedMessage
              description="switch organization page title"
              defaultMessage="Select an organization"
            />
          </h2>
          {/* <p className="mt-2 text-center text-sm text-gray-600">
              <FormattedMessage
                description="switch organization page message"
                defaultMessage={
                  "Select "
                }
              />
          </p> */}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 min-w-max">
            <ul role="list" className="divide-y divide-gray-200">
              {organizations.map(organization => (
                <li key={organization.id}>
                  <a
                    href="#"
                    onClick={() => onSelect(organization)}
                    className="block hover:bg-gray-100"
                  >
                    <div className="p-4">
                      <p className="font-medium text-gray-900 uppercase">
                        {organization.name}
                      </p>
                    </div>
                  </a>
                </li>
              ))}
              <li>
                <Link
                  to="/organizations/new"
                  state={location.state}
                  className="block hover:bg-gray-100"
                >
                  <div className="p-4">
                    <p className="font-medium text-indigo-700">
                      <FontAwesomeIcon icon={faPlus} className="mr-2" />
                      <FormattedMessage
                        description="switch organization page create new organization button label"
                        defaultMessage="Create New Organization"
                      />
                    </p>
                  </div>
                </Link>
              </li>
            </ul>
            <div>
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm"></div>
              </div>
              <div className="mt-6">
                <button
                  onClick={() => navigate(from, { replace: true })}
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm shadow-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <FormattedMessage
                    description="switch organization page cancel button label"
                    defaultMessage="Cancel"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CenteredLayout>
  )
}
