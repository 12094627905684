import React from 'react'
import { FormattedMessage } from 'react-intl'

export default function Billing() {
  return (
    <>
      <div className="mt-3">
        <div className="sm:flex sm:items-center">
          <div className="mt-4 sm:mt-0 sm:flex-auto">
            <p className="mt-2 text-sm text-gray-700">
              <FormattedMessage
                description="billing page description"
                defaultMessage="Manage your subscription"
              />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
