import { Q_JOIN } from '@/dash/constants'
import { useApi, useTenancy } from '@/dash/hooks'
import { CenteredLayout } from '@/dash/layouts'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as pages from '.'

export default function NewOrganization() {
  const api = useApi()
  const { organizations, selectOrganization } = useTenancy()
  const navigate = useNavigate()
  const { id: organizationId } = useParams()
  const [searchParams] = useSearchParams()
  const qClient = useQueryClient()
  const [disabled, setDisabled] = React.useState(false)

  const qJoin = useQuery(
    [Q_JOIN],
    () => api.checkInvite(organizationId, searchParams.get('code')),
    {
      retry: false,
      enabled: !disabled,
      onError: () => setDisabled(true),
    },
  )

  const mJoin = useMutation(
    () => api.acceptInvite(organizationId, searchParams.get('code')),
    {
      onSuccess: () => {
        qClient.invalidateQueries()
        qClient.removeQueries(Q_JOIN)
        selectOrganization(qJoin.data)
        navigate('/')
      },
    },
  )

  if (qJoin.isLoading) {
    return <pages.Loading />
  }

  // Display any errors due to invalid or expired codes to the user
  if (qJoin.error) {
    return (
      <pages.Error
        title={
          <FormattedMessage
            description="join page invalid code error title"
            defaultMessage="Invalid code"
          />
        }
        msg={
          <FormattedMessage
            description="join page invalid code error title"
            defaultMessage="Your join code was either invalid or expired."
          />
        }
      />
    )
  }

  // Prevent users from using a join code for an organization they
  // already belong to.
  if (organizations.map(o => o.id).includes(qJoin.data.id)) {
    return (
      <pages.Error
        prefix="👍"
        title={
          <FormattedMessage
            description="join page already joined error title"
            defaultMessage="Already a member"
          />
        }
        msg={
          <FormattedMessage
            description="join page already joined error message"
            defaultMessage="You are already a member of <b>{organization}</b>"
            values={{
              b: chunks => <strong>{chunks}</strong>,
              organization: qJoin.data.name,
            }}
          />
        }
      />
    )
  }

  return (
    <CenteredLayout>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            <FormattedMessage
              description="join organization page title"
              defaultMessage="You've been invited!"
            />
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            <FormattedMessage
              description="join organization page message"
              defaultMessage="You've been invited to join <b>{organization}</b> on MyEducator Analytics"
              values={{
                b: text => <strong>{text}</strong>,
                organization: qJoin.data.name,
              }}
            />
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <button
              onClick={mJoin.mutateAsync}
              className="bg-indigo-600 hover:bg-indigo-700 w-full flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={mJoin.isLoading}
            >
              <FormattedMessage
                description="join organization page accept button label"
                defaultMessage="Accept"
              />
            </button>
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm"></div>
              </div>
              <div className="mt-6">
                <button
                  onClick={() => navigate('/')}
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm shadow-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <FormattedMessage
                    description="join organization page cancel button label"
                    defaultMessage="Not right now"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CenteredLayout>
  )
}
