import { useAuth0 } from '@auth0/auth0-react'
import {
  faBarsStaggered,
  faChartMixed,
  faHome,
  faXmark,
  faGear,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useTenancy } from '@/dash/hooks'
import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, Outlet, useLocation } from 'react-router-dom'

const navigation = [
  {
    name: (
      <FormattedMessage
        description="navigation menu dashboard link"
        defaultMessage="Dashboard"
      />
    ),
    href: '/',
    icon: faHome,
  },
  {
    name: (
      <FormattedMessage
        description="navigation menu reports link"
        defaultMessage="Reports"
      />
    ),
    href: '/reports',
    icon: faChartMixed,
  },
  {
    name: (
      <FormattedMessage
        description="navigation menu settings link"
        defaultMessage="Settings"
      />
    ),
    href: '/settings',
    icon: faGear,
  },
]

const userNavigation = [
  {
    name: (
      <FormattedMessage
        description="dashboard user menu sign out link"
        defaultMessage="Sign Out"
      />
    ),
    href: '/logout',
  },
]

export default function AppLayout() {
  const location = useLocation()
  const { user } = useAuth0()
  const { organization } = useTenancy()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  // close the sidebar anytime location changes
  React.useEffect(() => {
    setSidebarOpen(false)
  }, [location])

  // determine which nav item to set as active
  const active =
    location.pathname === '/'
      ? navigation[0].href
      : navigation.slice(1).find(nav => location.pathname.startsWith(nav.href))
          ?.href

  return (
    <>
      <div>
        {/* Mobile sidebar nav */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"
                      alt="Workflow"
                    />
                  </div>
                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <nav className="px-2 space-y-1">
                      {navigation.map(item => (
                        <Link
                          key={item.href}
                          to={item.href}
                          className={classNames(
                            item.href === active
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                          )}
                        >
                          {item.icon && (
                            <FontAwesomeIcon
                              icon={item.icon}
                              className={classNames(
                                item.href === active
                                  ? 'text-gray-300'
                                  : 'text-gray-400 group-hover:text-gray-300',
                                'mr-4 flex-shrink-0 h-6 w-6',
                              )}
                              aria-hidden="true"
                            />
                          )}
                          {item.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Desktop sidebar nav */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
            <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"
                alt="Workflow"
              />
            </div>
            <div className="flex-1 flex flex-col overflow-y-auto">
              <nav className="flex flex-1 flex-col justify-between">
                <div className="px-2 py-4 space-y-1">
                  {navigation.map(item => (
                    <Link
                      key={item.href}
                      to={item.href}
                      className={classNames(
                        item.href === active
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                      )}
                    >
                      {item.icon && (
                        <FontAwesomeIcon
                          icon={item.icon}
                          className={classNames(
                            item.href === active
                              ? 'text-gray-300'
                              : 'text-gray-400 group-hover:text-gray-300',
                            'mr-3 flex-shrink-0 h-6 w-6',
                          )}
                          aria-hidden="true"
                        />
                      )}
                      {item.name}
                    </Link>
                  ))}
                </div>
              </nav>
            </div>
          </div>
        </div>

        {/* Top nav */}
        <div className="md:pl-64 flex flex-col">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <FontAwesomeIcon
                icon={faBarsStaggered}
                className="h-6 w-6"
                aria-hidden="true"
              />
            </button>
            <div className="flex-1 px-4 flex justify-end">
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={user.picture}
                        alt="user picture"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 divide-y divide-gray-100 max-w-xs mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {organization && (
                        <div className="py-1">
                          <Menu.Item disabled>
                            {() => (
                              <div className="block px-4 py-2 text-xs text-gray-400 uppercase">
                                {organization.name}
                              </div>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/organizations/switch"
                                state={{ from: location }}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700',
                                )}
                              >
                                Switch Organization
                              </Link>
                            )}
                          </Menu.Item>
                        </div>
                      )}
                      <div className="py-1">
                        <Menu.Item disabled>
                          {() => (
                            <div className="block px-4 py-2 text-xs text-gray-400 uppercase truncate">
                              {user.email}
                            </div>
                          )}
                        </Menu.Item>
                        {userNavigation.map((item, idx) => (
                          <Menu.Item key={idx}>
                            {({ active }) => (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700',
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1">
            <div className="py-6">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
