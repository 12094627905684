// localStorage keys
export const LS_TOKEN_KEY = 'token'
export const LS_LAST_LOGIN = 'lastLogin'
export const LS_ORGANIZATION_KEY = 'organization'
export const LS_AUTH_RETURN_LOCATION = 'authReturnLocation'

// query keys
export const Q_API_KEYS = 'apiKeys'
export const Q_CURRENT_USER = 'currentUser'
export const Q_ORGANIZATIONS = 'organizations'
export const Q_ORGANIZATION_USERS = 'organizationUsers'
export const Q_INVITES = 'invites'
export const Q_JOIN = 'join'
