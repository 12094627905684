import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

export default function Logout() {
  const auth0 = useAuth0()

  React.useEffect(() => {
    auth0.logout()
  })

  return null
}
