import {
  faCog,
  faCreditCard,
  faKeySkeleton,
  faUserGroup,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import ApiKeys from './ApiKeys'
import Billing from './Billing'
import General from './General'
import Team from './Team'

export default function Settings() {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Navigation />
      <Routes>
        <Route path="general" element={<General />} />
        <Route path="team" element={<Team />} />
        <Route path="api-keys" element={<ApiKeys />} />
        <Route path="billing" element={<Billing />} />
        <Route path="*" element={<Navigate to="general" replace />} />
      </Routes>
    </div>
  )
}

const tabs = [
  {
    name: (
      <FormattedMessage
        description="settings page nav general button label"
        defaultMessage="General"
      />
    ),
    href: 'general',
    icon: faCog,
  },
  {
    name: (
      <FormattedMessage
        description="settings page nav team button label"
        defaultMessage="Team"
      />
    ),
    href: 'team',
    icon: faUserGroup,
  },
  {
    name: (
      <FormattedMessage
        description="settings page nav api keys button label"
        defaultMessage="API Keys"
      />
    ),
    href: 'api-keys',
    icon: faKeySkeleton,
  },
  {
    name: (
      <FormattedMessage
        description="settings page nav billing button label"
        defaultMessage="Billing"
      />
    ),
    href: 'billing',
    icon: faCreditCard,
  },
]

function Navigation() {
  const navigate = useNavigate()
  const currentTab = tabs.find(tab => location.pathname.includes(tab.href))

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={currentTab?.href}
          onChange={e => navigate(e.target.value)}
        >
          {tabs.map(tab => (
            <option key={tab.href} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map(tab => (
              <Link
                key={tab.href}
                to={tab.href}
                className={classNames(
                  tab === currentTab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm',
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                <FontAwesomeIcon
                  icon={tab.icon}
                  aria-hidden="true"
                  className={classNames(
                    tab === currentTab
                      ? 'text-indigo-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5',
                  )}
                />
                <span>{tab.name}</span>
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
