import { useAuth0 } from '@auth0/auth0-react'
import {
  LS_ORGANIZATION_KEY,
  Q_ORGANIZATIONS,
  Q_ORGANIZATION_USERS,
} from '@/dash/constants'
import { useApi } from '@/dash/hooks'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const TenancyContext = React.createContext({})

export default function TenancyProvider({ children }) {
  const api = useApi()
  const { isAuthenticated, user: authUser } = useAuth0()
  const [currentOrg, setCurrentOrg] = React.useState({})
  const [currentUser, setCurrentUser] = React.useState()
  const [switching, setSwitching] = React.useState(false)
  const qClient = useQueryClient()
  const [isLoading, setIsLoading] = React.useState(true)

  const selectOrganization = React.useCallback(
    organization => {
      localStorage.setItem(LS_ORGANIZATION_KEY, organization.id)
      qClient.removeQueries()
      setCurrentOrg(organization)
      setSwitching(true)
      setTimeout(() => {
        setSwitching(false)
      }, 1000)
    },
    [setCurrentOrg, qClient],
  )

  React.useEffect(() => {
    if (qOrganizations.isLoading && qUsers.isLoading) {
      setIsLoading(true)
    } else if (currentOrg == undefined) {
      setIsLoading(false)
    } else {
      setIsLoading(false)
    } // can remove the last one once the qUsers is fixed.
  }, [currentOrg, currentUser])

  // list all organizations for the current user
  const qOrganizations = useQuery([Q_ORGANIZATIONS], api.fetchOrganizations, {
    enabled: isAuthenticated,
    onSuccess: data => {
      let organization

      // try setting the last org saved to localStorage
      if (localStorage.getItem(LS_ORGANIZATION_KEY)) {
        organization = data.find(
          o => o.id === localStorage.getItem(LS_ORGANIZATION_KEY),
        )
      }

      // if none was set previously, or we couldn't find it, set a new
      // org an save it to local storage
      if (!organization) {
        organization = data[0]
      }
      setCurrentOrg(organization)
    },
  })

  // list all organization users for the current organization
  const qUsers = useQuery(
    [Q_ORGANIZATION_USERS],
    () => api.fetchOrganizationUsers(currentOrg?.id),
    {
      enabled: isAuthenticated && !!currentOrg?.id,
      onSuccess: data => {
        let user
        user = data.find(u => u.email == authUser.email)
        setCurrentUser(user)
      },
    },
  )

  const mUpdateUser = useMutation(
    user => api.updateOrganizationUser(currentOrg.id, user.id, user),
    {
      onSuccess: () => {
        qClient.invalidateQueries(Q_ORGANIZATION_USERS)
      },
    },
  )

  const mDestroyUser = useMutation(
    user => api.destroyOrganizationUser(currentOrg.id, user.id),
    {
      onSuccess: () => {
        qClient.invalidateQueries(Q_ORGANIZATION_USERS)
      },
    },
  )

  const mDestroyOrganization = useMutation(
    () => {
      api.destroyOrganization(currentOrg.id)

      qClient.setQueryData([Q_ORGANIZATIONS], orgs =>
        orgs.filter(org => org.id !== currentOrg.id),
      )
    },
    {
      onMutate: async () => {
        const originalOrganizations = qClient.getQueriesData([Q_ORGANIZATIONS])

        qClient.setQueryData([Q_ORGANIZATIONS], old =>
          old.filter(org => org.id !== currentOrg.id),
        )

        return { originalOrganizations }
      },
    },
    {
      onError: (err, context) => {
        qClient.setQueriesData([Q_ORGANIZATIONS], context.originalOrganizations)
      },
    },
    {
      onSettled: async () => {
        await qClient.invalidateQueries(Q_ORGANIZATIONS, {
          refetchActive: true,
        })
      },
    },
  )

  return (
    <TenancyContext.Provider
      value={{
        isLoading: isLoading,
        isSwitching: switching,
        organization: currentOrg,
        organizations: qOrganizations?.data ?? [],
        user: currentUser,
        users: qUsers?.data ?? [],
        updateUser: mUpdateUser.mutateAsync,
        removeUser: mDestroyUser.mutateAsync,
        removeOrganization: mDestroyOrganization.mutateAsync,
        role: currentUser?.role,
        selectOrganization,
      }}
    >
      {children}
    </TenancyContext.Provider>
  )
}
