import { Dialog } from '@headlessui/react'
import { Modal } from '@/dash/components'
import { AddUserForm } from '@/dash/components/forms'
import { TeamTable } from '@/dash/components/tables'
import { Q_INVITES } from '@/dash/constants'
import { useApi, useTenancy } from '@/dash/hooks'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

export default function Team() {
  const { organization, users, updateUser, removeUser } = useTenancy()
  const api = useApi()
  const [show, setShow] = React.useState(false)
  const qClient = useQueryClient()

  const qInvites = useQuery([Q_INVITES], () =>
    api.fetchInvites(organization.id),
  )

  const mCreateInvite = useMutation(
    invite => api.createInvite(organization.id, invite),
    {
      onSuccess: () => {
        qClient.invalidateQueries(Q_INVITES)
        setShow(false)
      },
    },
  )

  const mDestroyInvite = useMutation(
    invite => api.destroyInvite(organization.id, invite.id),
    {
      onSuccess: () => {
        qClient.invalidateQueries(Q_INVITES)
      },
    },
  )

  return (
    <>
      <div className="mt-3">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="mt-2 text-sm text-gray-700">
              <FormattedMessage
                description="team page description"
                defaultMessage="Manage team members and their roles"
              />
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              disabled={qInvites.isLoading}
              onClick={() => setShow(true)}
            >
              <FormattedMessage
                description="team page add user button"
                defaultMessage="Add user"
              />
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <TeamTable
                  users={users}
                  invites={qInvites.isSuccess ? qInvites.data : []}
                  onRemoveInvite={mDestroyInvite.mutateAsync}
                  onChangeRole={updateUser}
                  onRemoveUser={removeUser}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add user modal */}
      <Modal show={show} dismiss={() => setShow(false)}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              <FormattedMessage
                description="team page add user modal title"
                defaultMessage="Add user"
              />
            </Dialog.Title>
          </div>
        </div>
        <div className="mx-2 sm:mx-4 my-3">
          <AddUserForm onSubmit={mCreateInvite.mutateAsync} />
        </div>
      </Modal>
    </>
  )
}
