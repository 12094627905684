import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

export default function Dashboard() {
  const [token, setToken] = React.useState()
  const { user, getAccessTokenSilently } = useAuth0()

  React.useEffect(() => {
    getAccessTokenSilently().then(t => setToken(t.split('.')))
  }, [getAccessTokenSilently])

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h2>USER</h2>
        <pre>{JSON.stringify(user, null, 2)}</pre>
        <h2>TOKEN HEADER</h2>
        {token && token[0] && (
          <pre>{JSON.stringify(JSON.parse(atob(token[0])), null, 2)}</pre>
        )}
        <h2>TOKEN PAYLOAD</h2>
        {token && token[1] && (
          <pre>{JSON.stringify(JSON.parse(atob(token[1])), null, 2)}</pre>
        )}
      </div>
    </>
  )
}
