import { NewOrganizationForm } from '@/dash/components/forms'
import { Q_ORGANIZATIONS } from '@/dash/constants'
import { useApi, useTenancy } from '@/dash/hooks'
import { CenteredLayout } from '@/dash/layouts'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

export default function NewOrganization() {
  const { selectOrganization } = useTenancy()
  const api = useApi()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  // Redirect to the page set in state or dashboard by default
  const from = location.state?.from?.pathname || '/'

  // Slightly different messaging, controls for initial organization
  const initial = searchParams.has('initial')

  const mCreateOrganization = useMutation(api.createOrganization, {
    onSuccess: data => {
      selectOrganization(data)
      queryClient.invalidateQueries(Q_ORGANIZATIONS)
    },
  })

  const onSubmit = async values => {
    await mCreateOrganization.mutateAsync(values)
    navigate(from, { replace: true })
  }

  return (
    <CenteredLayout>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            <FormattedMessage
              description="new organization page title"
              defaultMessage="Create an organization"
            />
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {initial ? (
              <FormattedMessage
                description="new organization page initial welcome message"
                defaultMessage={
                  "Welcome! To get started, create a new organization to track user analytics and manage your team's access. "
                }
              />
            ) : (
              <FormattedMessage
                description="new organization page message"
                defaultMessage={
                  "Create a new organization to track user analytics and manage your team's access. "
                }
              />
            )}
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <NewOrganizationForm onSubmit={onSubmit} />
            {!initial && (
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm"></div>
                </div>
                <div className="mt-6">
                  <button
                    onClick={() => navigate(from, { replace: true })}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm shadow-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <FormattedMessage
                      description="new organization page cancel button label"
                      defaultMessage="Cancel"
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </CenteredLayout>
  )
}
