import React from 'react'

export default function Centered({ children }) {
  return (
    <div className="bg-gray-50 min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">{children}</main>
      </div>
    </div>
  )
}
