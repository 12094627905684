import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as classNames from 'classnames'
import { Alert } from '@/dash/components'
import * as Formik from 'formik'
import React from 'react'
import { FormattedMessage } from 'react-intl'

/**
 * A headless component for updating formik status when a server error
 * has occurred.
 */
function FormikServerErrorHelper({ serverErrors }) {
  const { setStatus } = Formik.useFormikContext()

  React.useEffect(() => {
    setStatus({ serverErrors })
  }, [serverErrors, setStatus])

  return null
}

/**
 * Form
 *
 * A base form component that uses Formik. Most of our forms are server
 */
export default function Form({ onSubmit, children, ...formProps }) {
  const [serverError, setServerError] = React.useState()

  const _onSubmit = async values => {
    try {
      setServerError()
      await onSubmit(values)
    } catch (err) {
      setServerError(err)
    }
  }

  return (
    <>
      {serverError && (
        <Alert variant="danger" className="mb-2">
          <p>
            {serverError.response?.data?.detail ? (
              serverError.response.data.detail
            ) : serverError.response?.data?.non_field_errors ? (
              serverError.response.data.non_field_errors[0]
            ) : serverError.response?.data ? (
              <FormattedMessage
                description="general bad request (400) error message"
                defaultMessage="Please fix the errors below and try again."
              />
            ) : serverError.request ? (
              <FormattedMessage
                description="network response error message"
                defaultMessage="A network error occurred. Please try again."
              />
            ) : (
              <FormattedMessage
                description="unknown error message"
                defaultMessage="An unknown error occurred. Please try again."
              />
            )}
          </p>
        </Alert>
      )}
      <Formik.Formik
        onSubmit={_onSubmit}
        initialStatus={{ serverErrors: null }}
        {...formProps}
      >
        {formikBag => (
          <>
            <FormikServerErrorHelper
              serverErrors={serverError?.response?.data}
            />
            <Formik.Form>{children(formikBag)}</Formik.Form>
          </>
        )}
      </Formik.Formik>
    </>
  )
}

/**
 * InputGroup
 *
 * A text input group that supports hints, error messsages, and more!
 */
Form.InputGroup = function InputGroup({
  label,
  labelProps,
  hint,
  ...fieldProps
}) {
  const {
    status: { serverErrors },
  } = Formik.useFormikContext()
  const [field, meta] = Formik.useField(fieldProps)
  return (
    <div>
      {label && (
        <label
          htmlFor={fieldProps.id}
          className="block text-sm font-medium text-gray-700"
          {...labelProps}
        >
          {label}
        </label>
      )}
      <div className="mt-1">
        <input
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          {...field}
          {...fieldProps}
        />
      </div>
      {hint && (
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {hint}
        </p>
      )}
      {meta.error && meta.touched && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {meta.error}
        </p>
      )}
      {serverErrors?.[fieldProps.name] && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {serverErrors?.[fieldProps.name]}
        </p>
      )}
    </div>
  )
}

/**
 * CheckboxInputGroup
 *
 * A checkbox input group that supports hints, error messsages, and more!
 */
Form.CheckboxInputGroup = function CheckboxInputGroup({
  label,
  labelProps,
  hint,
  ...fieldProps
}) {
  const {
    status: { serverErrors },
  } = Formik.useFormikContext()
  const [field, meta] = Formik.useField(fieldProps)
  return (
    <div>
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={field.value}
          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          {...field}
          {...fieldProps}
        />
        {label && (
          <label
            htmlFor={fieldProps.id}
            className="ml-2 block text-sm text-gray-900"
            {...labelProps}
          >
            {label}
          </label>
        )}
      </div>
      {hint && (
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {hint}
        </p>
      )}
      {meta.error && meta.touched && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {meta.error}
        </p>
      )}
      {serverErrors?.[fieldProps.name] && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {serverErrors?.[fieldProps.name]}
        </p>
      )}
    </div>
  )
}

Form.SubmitButton = function SubmitButton({ children, disabled, ...btnProps }) {
  const { isSubmitting } = Formik.useFormikContext()

  const _disabled = isSubmitting || disabled

  return (
    <button
      type="submit"
      className={classNames(
        _disabled ? 'bg-indigo-400' : 'bg-indigo-600 hover:bg-indigo-700',
        'w-full flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
      )}
      disabled={_disabled}
      {...btnProps}
    >
      {children}
      {isSubmitting && (
        <FontAwesomeIcon icon={faSpinner} className="ml-2 fa-spin align-" />
      )}
    </button>
  )
}
