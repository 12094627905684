import React from 'react'
import { FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import Form from './Base'

const schema = Yup.object().shape({
  label: Yup.string().required('Required'),
})

export default function AddApiKey({ ...formProps }) {
  return (
    <Form
      initialValues={{
        label: '',
      }}
      validationSchema={schema}
      {...formProps}
    >
      {() => (
        <div className="space-y-6">
          <Form.InputGroup
            id="label"
            name="label"
            required
            label={
              <FormattedMessage
                description="add api key form name input label"
                defaultMessage="Name"
              />
            }
          />
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Form.SubmitButton>
              <FormattedMessage
                description="add api key form submit button label"
                defaultMessage="Add"
              />
            </Form.SubmitButton>
          </div>
        </div>
      )}
    </Form>
  )
}
