import React from 'react'
import { IntlProvider } from 'react-intl'

/**
 * Supported locales
 */
export const supportedLocales = [
  { key: 'en', name: 'English' },
  // { key: 'es', name: 'Español' },
]

/**
 * Default locale for i18n
 */
export const defaultLocale = supportedLocales[0].key

/**
 * Custom context for managing locale.
 */
export const I18nContext = React.createContext({
  locale: defaultLocale,
  setLocale: () => {},
})

/**
 * Wrapper component for managing the current locale. We default to the
 * user's current browser setting, falling back to our `defaultLocale`
 * if necessary.
 */
export default function I18nProvider({ children }) {
  // Set the locale
  const [locale, setLocale] = React.useState(
    typeof navigator !== 'undefined'
      ? navigator.language.split('-')[0]
      : defaultLocale,
  )
  const [messages] = React.useState({})

  // Select the messages for the locale
  React.useEffect(() => {
    const loadMessages = async () => {
      // TODO: load messages
      // const _messages = await import(`i18n/lang/${locale}.json`)
      // setMessages(_messages)
    }

    loadMessages()
  }, [locale])

  return (
    <I18nContext.Provider
      value={{
        locale,
        setLocale,
        supportedLocales,
        defaultLocale,
      }}
    >
      <IntlProvider
        messages={messages}
        locale={locale}
        defaultLocale={defaultLocale}
      >
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  )
}
