import { Dialog } from '@headlessui/react'
import { Modal } from '@/dash/components'
import { AddApiKeyForm, ConfirmDeleteForm } from '@/dash/components/forms'
import { ApiKeysTable } from '@/dash/components/tables'
import { Q_API_KEYS } from '@/dash/constants'
import { useApi, useTenancy } from '@/dash/hooks'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export default function ApiKeys() {
  const { organization } = useTenancy()
  const api = useApi()
  const [show, setShow] = React.useState(false)
  const [newApiKey, setNewApiKey] = React.useState()
  const [removeApiKey, setRemoveApiKey] = React.useState()
  const qClient = useQueryClient()

  const qKeys = useQuery([Q_API_KEYS], () => api.fetchApiKeys(organization.id))

  const mCreateApiKey = useMutation(
    apiKey => api.createApiKey(organization.id, apiKey),
    {
      onSuccess: data => {
        qClient.invalidateQueries(Q_API_KEYS)
        setShow(false)
        setNewApiKey(data.secret)
      },
    },
  )

  const mDestroyApiKey = useMutation(
    apiKey => api.destroyApiKey(organization.id, apiKey.id),
    {
      onSuccess: () => {
        qClient.invalidateQueries(Q_API_KEYS)
        setRemoveApiKey()
      },
    },
  )

  return (
    <>
      <div className="mt-3">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="mt-2 text-sm text-gray-700">
              <FormattedMessage
                description="api key page description"
                defaultMessage="Manage API keys used to send analytics to your account"
              />
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              disabled={qKeys.isLoading}
              onClick={() => setShow(true)}
            >
              <FormattedMessage
                description="api key page add key button"
                defaultMessage="Add API key"
              />
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <ApiKeysTable
                  apiKeys={qKeys.isSuccess ? qKeys.data : []}
                  onRemoveKey={setRemoveApiKey}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add key modal */}
      <Modal show={show} dismiss={() => setShow(false)}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              <FormattedMessage
                description="api key page add key modal title"
                defaultMessage="Add API Key"
              />
            </Dialog.Title>
          </div>
        </div>
        <div className="mx-2 sm:mx-4 my-3">
          <AddApiKeyForm onSubmit={mCreateApiKey.mutateAsync} />
        </div>
      </Modal>

      {/* Created key modal */}
      <Modal show={!!newApiKey}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              <FormattedMessage
                description="api key page new key modal title"
                defaultMessage="Your new API Key"
              />
            </Dialog.Title>
          </div>
        </div>
        <div className="mx-2 sm:mx-4 my-3">
          <p className="mb-3 font-bold text-sm uppercase text-gray-500 ">
            <FormattedMessage
              description="api key page new key message title"
              defaultMessage="Keep your key safe"
            />
          </p>
          <p className="mb-3">
            <FormattedMessage
              description="api key page new key message body"
              defaultMessage="Save and store this new key to a secure place, such as a password manager or secret store. You won’t be able to see it again."
            />
          </p>
          <p className="p-4 rounded-md bg-gray-200 font-mono text-gray-800 break-all">
            {newApiKey}
          </p>
          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm"></div>
            </div>
            <div className="mt-6">
              <button
                onClick={() => setNewApiKey()}
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm shadow-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <FormattedMessage
                  description="api key page new key close button label"
                  defaultMessage="Close"
                />
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Remove key modal */}
      <Modal show={!!removeApiKey} dismiss={() => setRemoveApiKey()}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              <FormattedMessage
                description="api key page remove key modal title"
                defaultMessage="Remove API Key"
              />
            </Dialog.Title>
          </div>
        </div>
        <div className="mx-2 sm:mx-4 my-3">
          <p className="mb-3">
            <FormattedMessage
              description="api key page new key message body"
              defaultMessage="You will be unable to perform any more actions with this key. "
            />
          </p>
        </div>
        <div className="mx-2 sm:mx-4 my-3">
          <ConfirmDeleteForm
            onSubmit={() => mDestroyApiKey.mutateAsync(removeApiKey)}
          />
        </div>
      </Modal>
    </>
  )
}
