import React from 'react'
import { FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import Form from './Base'

const schema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

export default function NewOrganization({ ...formProps }) {
  return (
    <Form
      initialValues={{
        name: '',
      }}
      validationSchema={schema}
      {...formProps}
    >
      {() => (
        <div className="space-y-6">
          <Form.InputGroup
            id="name"
            name="name"
            required
            label={
              <FormattedMessage
                description="new organization form organization name input label"
                defaultMessage="Organization name"
              />
            }
          />
          <Form.SubmitButton>
            <FormattedMessage
              description="new organization form submit button label"
              defaultMessage="Create"
            />
          </Form.SubmitButton>
        </div>
      )}
    </Form>
  )
}
